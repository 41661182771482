import { IFacilityByCountry } from "@/models/Facility";
import { ICountries, IFacilitiesByCountry } from "@/models/Metadata";
import { ErrorResponse } from "@/services/axios/error";
import { MetadataAPI } from "@/services/metadata";
import store from "@/store";
import { distinctCountriesData } from "@/utils/helpers/buildDataFormat";
import { convertDataFacilitiesMonitoring } from "@/utils/helpers/buildDataFormat";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "MetadataModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class MetadataModule extends VuexModule {
  private errorGetFacilities = {} as ErrorResponse;
  private dataFacilities = [] as IFacilitiesByCountry[];
  private dataFacilitiesOnline = [] as IFacilitiesByCountry[];
  private dataFacilitiesMap = [] as IFacilitiesByCountry[];
  private dataCountriesId = "" as string;
  private isLoadMetadata = false;

  private dataCountries = [] as ICountries[];
  private dataInputManualCountries = [] as ICountries[];
  get dataFacilitiesGetter() {
    return this.dataFacilities;
  }
  get dataFacilitiesOnlineGetter() {
    return this.dataFacilitiesOnline;
  }
  get dataFacilitiesMapGetter() {
    return this.dataFacilitiesMap;
  }

  get dataCountriesGetter() {
    return this.dataCountries;
  }
  get dataCountriesIdGetter() {
    return this.dataCountriesId;
  }
  get dataInputManualCountriesGetter() {
    return this.dataInputManualCountries;
  }

  get isLoadMetaDataGetter() {
    return this.isLoadMetadata;
  }
  @Mutation
  setLoadingMetadata(isLoading: boolean) {
    this.isLoadMetadata = isLoading;
  }
  @Mutation
  setCurrentCountries(data: string) {
    this.dataCountriesId = data;
  }
  @Mutation
  getFacilityByCountrySuccess(data: IFacilitiesByCountry[]) {
    this.errorGetFacilities = {} as ErrorResponse;
    this.dataCountries = distinctCountriesData(data) as ICountries[];
    this.dataInputManualCountries = [...this.dataCountries] as ICountries[];
    this.dataInputManualCountries.unshift({
      Name: "All",
      Value: null,
    });
    this.dataFacilities = data;
    this.dataFacilitiesOnline = data.filter(
      (item: IFacilitiesByCountry) => item?.IsOnline
    );
    this.dataFacilitiesMap = convertDataFacilitiesMonitoring(data).filter(
      (item: any) => item?.isOnline
    );

    this.isLoadMetadata = false;
  }
  @Mutation
  getFacilityByCountryFailure(error: ErrorResponse) {
    this.errorGetFacilities = error;
    this.isLoadMetadata = false;
  }
  @Action
  async getFacilityByCountryAction(payload: IFacilityByCountry = {}) {
    const MetadataService = new MetadataAPI();
    this.setLoadingMetadata(true);
    const { data, error } = await MetadataService.getFacilityByCountry(payload);
    if (error) {
      this.getFacilityByCountryFailure(error);
      return;
    }
    this.getFacilityByCountrySuccess(data);
  }
}

export default getModule(MetadataModule);
