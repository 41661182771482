import AccountModule from "@/store/modules/Account";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import queryString from "query-string";
import { IDataResponse } from "./entities";
import { IServiceError } from "./error";

// declare module 'axios' {
//   interface AxiosResponse<T = any> extends Promise<T> {}
// }
export default abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected readonly token?: string;

  constructor(baseURL?: string, token?: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        "content-type": "application/json",
      },
      paramsSerializer: (params) => queryString.stringify(params),
    });
    this.token = token || "";
    this.requestInterceptor();
    this.responseInterceptor();
  }

  private requestInterceptor = () => {
    this.instance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        (config.headers as any).Authorization = AccountModule.getUserInfo
          ?.authorizationString
          ? AccountModule.getUserInfo?.authorizationString
          : `Bearer ${this.token}`;
        return config;
      }
    );
  };

  /*
   * When response code is 401, try to refresh the token.
   * Eject the interceptor so it doesn't loop in case
   * token refresh causes the 401 response
   */
  // axios.interceptors.response.eject(interceptor);
  private responseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this.handleError
    );
  };
  private _handleResponse = ({ data, config }: AxiosResponse): any => {
    const res = data as IDataResponse;
    const resHeaders = config.url;
    if (res.Code !== "Success") {
      const error = {
        headers: resHeaders,
        message: res.Message,
        isError: true,
        data: res.Data,
        code: res.Code,
      } as IServiceError;

      return Promise.reject(error);
    }

    return { data: res.Data };
  };

  protected handleError = (error: AxiosError): void => {
    const parsedHash = window.location.hash.split("?");

    if (
      error &&
      error.response?.status === 401 &&
      parsedHash.length > 0 &&
      parsedHash[0] !== "#/login"
    ) {
      window.location.reload();
    }

    throw error;
  };
}
