import { ObjectDirective } from "vue";
import "./ellipsis.css";
import { setPopoverPosition } from "./popper";

const isTextTruncated = (el: HTMLElement): boolean => {
  return el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight;
};
const handelMouseOver = (el: any, binding: any) => {
  if (isTextTruncated(el)) {
    const placement = binding?.value?.placement || "top";

    const popper = document.createElement("div");
    popper.classList.add("v-tooltip__popper", `v-tooltip--${placement}`);

    const arrow = document.createElement("div");
    arrow.className = "v-tooltip__arrow";
    popper.appendChild(arrow);

    const content = document.createElement("div");
    content.className = "v-tooltip__content";
    content.innerHTML = el.innerHTML;
    popper.appendChild(content);

    document.body.appendChild(popper);

    setPopoverPosition(popper, el, placement);
  }
};
const handelMouseOut = () => {
  const dom = document.querySelector(".v-tooltip__popper");
  dom && dom.parentNode?.removeChild(dom);
};

export const vEllipsis = {
  beforeMount(el, binding) {
    const { style } = el;
    style.overflow = "hidden";
    style.textOverflow = "ellipsis";
    const props = binding.value;

    if (props && props.line && props.line > 1) {
      style.display = "-webkit-box";
      style.webkitBoxOrient = "vertical";
      style.webkitLineClamp = props.line;
    } else {
      style.whiteSpace = "nowrap";
    }

    el.addEventListener(
      "mouseover",
      handelMouseOver.bind(this, el, binding),
      false
    );
    el.addEventListener("mouseout", handelMouseOut, false);
  },
  unmounted(el) {
    el.removeEventListener("mouseover", handelMouseOver);
    el.removeEventListener("mouseout", handelMouseOut);
  },
} as ObjectDirective;
